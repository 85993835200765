import type { MutableRefObject } from 'react'
import { useEffect } from 'react'

export default function useOnClickOutside( 
  ref: MutableRefObject<HTMLElement>, 
  handler, 
  skip = false, 
) {
  useEffect(
    () => {
      if ( !skip ) {
        const listener = event => {
          // Do nothing if clicking ref's element or descendent elements
          if ( !ref.current || ref.current.contains( event.target ) ) {
            return
          }

          handler( event )
        }

        document.addEventListener( 'mousedown', listener, { passive: false } )
        document.addEventListener( 'touchstart', listener, { passive: false } )

        return () => {
          document.removeEventListener( 'mousedown', listener )
          document.removeEventListener( 'touchstart', listener )
        }
      }
    },
    [ref, handler, skip],
  )
}
