import ApolloProvider from '../graphql/ApolloProvider'
import ThemeProvider from '../styles/ThemeProvider'
import { AuthProvider } from '../lib/auth'
import { CookieConsentProvider } from './CookieConsent'
import { AppProvider } from './AppContext'
import { ModalProvider } from '../lib/modal'
import Modal from './Modal'
import { LightboxProvider } from './Lightbox'

function ContextProviders( { children } ) {
  return (
    <AuthProvider>
      <ApolloProvider>
        <ThemeProvider>
          <CookieConsentProvider>
            <AppProvider>
              <ModalProvider Modal={ Modal }>
                <LightboxProvider>
                  { children }
                </LightboxProvider>
              </ModalProvider>
            </AppProvider>
          </CookieConsentProvider>
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  )
}

export default ContextProviders
