import styled from 'styled-components'
import { useModalContext } from '../../lib/modal'
import LoginForm from '../LoginForm'
import Stack from '../layout/Stack'

function LoginModal() {
  const { setIsLoading, openModal } = useModalContext()

  const onSuccess = ( { email } ) => {
    openModal( {
      remount: false,
      content: (
        <>
          <h1>Check your inbox</h1>
          <p>We sent an email to <strong>{ email }</strong> with instructions.</p>
        </>
      ),
    } )
  }

  return (
    <StyledLoginModalContent>
      <h1>
        <b>To register add your blue space</b> 
        <span>or</span>
        <b>Log back in</b>
      </h1>
      <Stack $between={ 2 }>
        <p>
          <strong>Remember</strong> - We need the location of your local blue space (your nearest 
          river, coast, stream, lake), not your school address. 
        </p>
        {/* <p>
          To get started, enter your email address to log in or create a new 
          account for your school (or a club/group/individual).
        </p> */}
        <LoginForm 
          setIsLoading={ setIsLoading } 
          onSuccess={ onSuccess }
        />
      </Stack>
    </StyledLoginModalContent>
  )
}

const StyledLoginModalContent = styled.div`
  max-width: 420px;
  margin: 0 auto;

  h1 {
    margin-bottom: 2rem;

    span {
      display: block;
      margin: 1rem 0;
      ${ p => p.theme.typo.satoshiBold }
      font-size: ${ p => p.theme.typo.sizes.bumped };
    }
    
    b {
      display: block;
      ${ p => p.theme.typo.diaryNotes }

      &:first-of-type {
        color: ${ p => p.theme.colors.turquoise };
      }
      
      &:nth-of-type( 2 ) {
        color: ${ p => p.theme.colors.yellow };
      }
    }
  }
`

export default LoginModal
