export type ColorKeys = 
  'black' 
  | 'white'
  | 'navy'
  | 'yellow'
  | 'skyBlue'
  | 'turquoise'

const colors = {
  black: '#000000',
  white: '#ffffff',
  navy: '#0D4466',
  yellow: '#F9B233',
  skyBlue: '#D5EBEA',
  turquoise: '#1CABAB',
  whiteBlueTint: '#D5EBEA',
  disabledIconBgGrey: '#D9D9D9',
  disabledIconFgGrey: '#78878F',
  red: '#CC3E3E',
}

export const getColor = ( color: ColorKeys ) => {
  if ( colors[ color ] ) {
    return colors[ color ]
  }

  return color
}

export default colors
