import Head from 'next/head'
import Custom404 from './404'
import ContextProviders from '../components/ContextProviders'
import { GAProvider } from '../lib/ga'
import '../styles/global.css'
import '../styles/map.css'

const gaTrackingId = 'UA-118995865-1'

function App( { Component, pageProps } ) {
  return (
    <GAProvider gaTrackingId={ gaTrackingId }>
      <ContextProviders>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon.icns" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        </Head>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status !== 404 && <Component { ...pageProps } /> }
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status === 404 && <Custom404 { ...pageProps } /> }
      </ContextProviders>
    </GAProvider>
  )
}

export default App
