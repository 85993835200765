import { useState } from 'react'
import Stack from './layout/Stack'
import Container from './layout/Container'
import FormInput from './inputs/FormInput'
import Button from './Button'

function LoginForm( { setIsLoading = null, onSuccess } ) {
  const [email, setEmail] = useState( '' )

  const submit = e => {
    e.preventDefault()

    if ( setIsLoading ) {
      setIsLoading( true )
    }
    
    fetch( '/api/auth/email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( {
        email,
      } ),
    } )
      .then( result => result.json() )
      .then( jsonResult => {
        if ( jsonResult?.error ) {
          throw new Error( jsonResult.error )
        }

        onSuccess( jsonResult )
      } )
      .finally( () => {
        if ( setIsLoading ) {
          setIsLoading( false )
        }
      } )
  }

  return (
    <form onSubmit={ submit }>
      <Container max={ 25 }>
        <Stack $between={ 2 }>
          <FormInput
            label="Email address"
            value={ email }
            onChange={ e => setEmail( e.target.value ) }
          />
          <Button>
            <button
              type="submit"
            >
              Let’s go
            </button>
          </Button>
        </Stack>
      </Container>
    </form>
  )
}

export default LoginForm
