import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import { CookieConsentBanner } from './CookieConsent'
import Loader from './Loader'

function AppChrome( { 
  footer = true,
  isLoading = false,
  children, 
} ) {
  return (
    <StyledAppChrome>
      <Header />
      <StyledAppMain>
        { children }
      </StyledAppMain>
      { footer && <Footer /> }
      <CookieConsentBanner />
      <Loader
        isLoading={ isLoading }
        fullscreen
      />
    </StyledAppChrome>
  )
}

const StyledAppChrome = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${ p => p.theme.colors.skyBlue };
  background-image: url("https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/wod-header-2024.jpg");
  background-size: 100% 600px;
  cursor: ${ p => p.theme.isLoading ? 'wait' : 'auto' };
`

const StyledAppMain = styled.div`
  z-index: 0;
  flex: 1;
  margin-top: 5.5rem;

  @media ( max-width: 800px ) {
    margin-top: 7rem;
  }
`

export default AppChrome
