import styled from 'styled-components'
import Container from './layout/Container'

function PageTitle( { children } ) {
  return (
    <StyledPageTitle>
      <StyledBackground />
      <Container>
        <StyledHeading>
          <h1>
            { children }
          </h1>
        </StyledHeading>
      </Container>
    </StyledPageTitle>
  )
}

const StyledPageTitle = styled.div`
  z-index: 1;
  position: relative;

  /*&:after {
    content: '';
    position: relative;
    display: block;
    height: 2.5rem;
    margin-bottom: -2.45rem;
    background-color: ${ p => p.theme.colors.skyBlue };
    mask-image: url( /masks/waves.svg );
    mask-position: bottom center;
    mask-repeat: repeat-x;
  }*/
`

const StyledBackground = styled.div`
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate( min( -670px, -53.5vw ), -5% );
  width: 287px;
`

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 7rem;

  h1 {
    z-index: 1;
    max-width: 700px;
    margin: 0 auto;
    ${ p => p.theme.typo.diaryNotes }
    // background-image: url(https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/Drop-v2.png);
    // padding-top: 100px;
    padding-bottom:40px;
    background-size: 50px auto;
    background-position: center top;
    background-repeat: no-repeat;
    font-size: ${ p => p.theme.typo.sizes.pageTitle };
    text-align: center;
    color: ${ p => p.theme.colors.navy };

    a {
      display: block;
      margin-top: 0.5rem;
      ${ p => p.theme.typo.satoshi }
      font-size: ${ p => p.theme.typo.sizes.dropped };
      color: inherit;

    }
  }
`

export default PageTitle
