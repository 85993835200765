import Head from 'next/head'
import Container from '../components/layout/Container'
import Background from '../components/layout/Background'
import Stack from '../components/layout/Stack'
import AppChrome from '../components/AppChrome'
import PageTitle from '../components/PageTitle'
import StyledTextContent from '../components/StyledTextContent'

function Custom404() {
  return (
    <AppChrome>
      <Head>
        <title>Page Not Found — World Ocean Day for Schools</title>
      </Head>
      <PageTitle>
        Page Not Found
      </PageTitle>
      <Background $color="turquoise">
        <Container max={ 28 }>
          <Stack 
            $top={ 5 }
            $bottom={ 15 }
          >
            <StyledTextContent>
              <p>There isn’t a page at this address.</p>
            </StyledTextContent>
          </Stack>
        </Container>
      </Background>
    </AppChrome>
  )
}

export default Custom404
