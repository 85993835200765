import styled from 'styled-components'
import { rgba } from 'polished'
import Link from './Link'
import Container from './layout/Container'
import Logo from './Logo'
import Nav from './Nav'

function Header() {
  return (
    <StyledHeader>
      <Container>
        <StyledHeaderLogoWrapper>
          <div>
            <StyledHeaderLogo>
              <Link href="https://worldoceanday.school/">
                <Logo color={ 'turquoise' as const } />
              </Link>
            </StyledHeaderLogo>
          </div>
        </StyledHeaderLogoWrapper>
      </Container>
      <StyledHeaderNav>
        <Container>
          <Nav />
        </Container>
      </StyledHeaderNav>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  z-index: 1;
  position: relative;
`

const StyledHeaderLogoWrapper = styled.div`
  position: relative;

  > div {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const StyledHeaderLogo = styled.div`
  z-index: 1;
  position: relative;
  width: 88px;
  padding: 0.8rem;
  background: ${ p => p.theme.colors.navy };
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;

  a,
  svg {
    display: block;
  }
`

const StyledHeaderNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // border-bottom: 1px solid ${ p => rgba( p.theme.colors.turquoise, 0.25 ) };
  border-bottom: 1px solid #0D4466;
  background: ${ p => p.theme.colors.skyBlue };
  background-image: url("https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/wod-header-2024.jpg");
  background-size: 100% 600px;

`

export default Header
