export type TypeActions =
  | 'LOG_IN_AS'
  | 'EXPORT_DATA'
  | 'UPDATE_FOR_SCHOOL'
  | 'DELETE'
  | 'SIGN_MANIFESTO_FOR_SCHOOL'

const intersects = ( arr1, arr2 ) => arr1.filter( i1 => arr2.find( i2 => i2 === i1 ) ).length > 0

const can = ( allowedRoles, userProps ) => ( action: TypeActions, props = {} as any ) => {
  if ( !allowedRoles ) {
    return false
  }

  if ( action === 'UPDATE_FOR_SCHOOL' ) {
    if ( intersects( allowedRoles, ['ADMIN', 'SUPER_ADMIN'] ) ) {
      return true
    }
    
    if ( 
      allowedRoles.includes( 'SCHOOL' ) 
      && props?.private?.user_id
      && userProps?.id
      && props.private.user_id === userProps.id
    ) {
      return true
    }
  }
  
  if ( action === 'SIGN_MANIFESTO_FOR_SCHOOL' ) {    
    if ( 
      allowedRoles.includes( 'SCHOOL' ) 
      && props?.private?.user_id
      && userProps?.id
      && props.private.user_id === userProps.id
    ) {
      return true
    }
  }

  if ( action === 'DELETE' ) {
    return intersects( allowedRoles, ['SUPER_ADMIN', 'ADMIN'] )
  }

  if ( action === 'LOG_IN_AS' ) {
    return intersects( allowedRoles, ['SUPER_ADMIN'] )
  }

  if ( action === 'EXPORT_DATA' ) {
    if ( allowedRoles.includes( 'ADMIN' ) ) {
      return true
    }
  }

  return false
}

export default can
