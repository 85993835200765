import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const getHeaders = authToken => {
  const authHeader = authToken ? { Authorization: `Bearer ${ authToken }` } : {}
  const hostHeader = typeof window === 'undefined' ? { Host: process.env.APP_HOSTNAME } : {}

  return {
    ...authHeader,
    ...hostHeader,
  }
}

const client = ( authToken = null ) => {
  if ( typeof window === 'undefined' ) {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  }

  const httpLink = createHttpLink( {
    uri: typeof window === 'undefined' ? 'https://caddy/gql/v1/graphql' : '/gql/v1/graphql',
  } )

  const authLink = setContext( ( _, { headers } ) => ( {
    headers: {
      ...headers,
      ...getHeaders( authToken ),
    },
  } ) )

  return new ApolloClient( {
    link: authLink.concat( httpLink ),
    cache: new InMemoryCache(),
  } )
}

export default client
