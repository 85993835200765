import { useRef, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { logout, useAuth } from '../lib/auth'
import { useModalContext } from '../lib/modal'
import useModalTraits from '../hooks/useModalTraits'
import Link from './Link'
import LoginModal from './modals/LoginModal'
import HamburgerIcon from './icons/HamburgerIcon'

const navBreakpoint = 700

const navItems = [
  {
    id: 1,
    href: 'https://worldoceanday.school',
    text: 'Home',
  },
  {
    id: 3,
    href: 'https://worldoceanday.school/about',
    text: 'About',
  },
  {
    id: 4,
    href: 'https://worldoceanday.school/resources-landing-page',
    text: 'Resources',
  },
  {
    id: 6,
    href: '/',
    text: 'Our Blue',
  },
  {
    id: 5,
    href: 'https://worldoceanday.school/register',
    text: 'Register',
  },
]

function isActive( href ) {
  if ( href === '/' ) {
    return true
  }

  return false
}

function Nav() { 
  const containerRef = useRef()
  const router = useRouter()
  const pathWithoutQuery = router.asPath.split( '?' )[ 0 ]
  const { openModal, closeModal } = useModalContext()
  const [isOpen, setIsOpen] = useState( false )
  const toggleNav = () => setIsOpen( s => !s )

  useModalTraits( {
    containerRef,
    isOpen,
    close: () => setIsOpen( false ),
  } )

  useEffect( () => {
    if ( router.query.register === '' ) {
      openModal( {
        content: <LoginModal />,
        onClose: () => {
          delete router.query.register
          router.push( pathWithoutQuery, undefined, { shallow: true } )
        },
      } )
    }
    else {
      closeModal()
    }
  }, [router.query.register] )
  
  return (
    <StyledNav>
      <StyledNavToggle>
        <button
          type="button"
          aria-label="Toggle navigation menu"
          onClick={ toggleNav }
        >
          <HamburgerIcon />
        </button>
      </StyledNavToggle>
      <StyledNavPanel 
        ref={ containerRef }
        $isOpen={ isOpen }
      >
        <StyledNavList>
          { navItems.map( navItem => {
            const { href } = navItem

            return (
              <StyledNavItem 
                key={ navItem.id }
                $isActive={ isActive( href ) }
              >
                { href && (
                  <Link href={ href }>
                    { navItem.text }
                  </Link>
                ) }
              </StyledNavItem>
            )
          } ) }
        </StyledNavList>
        <WODMapNav />
      </StyledNavPanel>
    </StyledNav>
  )
}

export function WODMapNav() {
  const router = useRouter()
  const pathWithoutQuery = router.asPath.split( '?' )[ 0 ]
  const me = useAuth()

  const logOut = () => {
    logout().then( () => {
      window.location.href = '/' 
    } )
  }

  if ( me?.user ) {
    return (
      <StyledWODMapNav>
        { me.user.role === 'SCHOOL' && (
          <Link href="/dashboard">
            Dashboard
          </Link>
        ) }
        { me.user.role === 'ADMIN' && (
          <Link href="/admin">
            Admin
          </Link>
        ) }
        <Link href="/">
          Map
        </Link>
        <button
          type="button"
          onClick={ logOut }
        >
          Log out
        </button>
      </StyledWODMapNav>
    )
  }

  return (
    <StyledWODMapNav>
      <Link href={ `${ pathWithoutQuery }?register` }>
        Log in / Register
      </Link>
      <Link href="/">
        Map
      </Link>
    </StyledWODMapNav>
  )
}

const StyledNav = styled.div`

`

const StyledNavToggle = styled.div`
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    border-radius: 100%;
    background: ${ p => p.theme.colors.skyBlue };
    -webkit-appearance: none;
    -webkit-border-radius: none;

    > svg path {
      fill: ${ p => p.theme.colors.navy };
    }
  }

  @media ( min-width: ${ navBreakpoint }px ) {
    display: none;
  }
`

const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  color: ${ p => p.theme.colors.navy };

  @media ( min-width: ${ navBreakpoint }px ) {
    justify-content: flex-end;
  }

  @media ( min-width: 880px ) {
    justify-content: center;
  }

  @media ( max-width: ${ navBreakpoint - 1 }px ) {
    flex-direction: column;
    align-items: flex-start;
    font-size: ${ p => p.theme.typo.sizes.normal };
  }
`

const StyledNavItem = styled.li<{ $isActive: boolean }>`
  display: block;

  a,
  button {
    display: block;
    margin: 0;
    padding: 0.6rem;
    background: transparent;
    border: 0;
    color: inherit;
    ${ p => p.theme.typo.satoshiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    text-decoration: none;
    cursor: pointer;
    transition: color .3s ease-out;

    &:hover {
      color: #acdade;
    }
  }

  ${ p => p.$isActive && css`
    a {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  ` }

  @media ( max-width: ${ navBreakpoint - 1 }px ) {
    a {
      padding: 0.6rem 1rem;
    }
  }
`

const StyledWODMapNav = styled.div`
  button,
  a {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0 1rem;
    border: 0;
    box-shadow: 0 0 3px 0 ${ p => rgba( p.theme.colors.navy, 0.5 ) };
    background: ${ p => p.theme.colors.yellow };
    color: ${ p => p.theme.colors.navy };
    ${ p => p.theme.typo.satoshiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    text-decoration: none;
    cursor: pointer;
    transition:
      background .3s ease-out,
      color .3s ease-out;

    &:hover {
      background: ${ p => p.theme.colors.navy };
      color: ${ p => p.theme.colors.yellow };
    }
  }

  @media ( max-width: ${ navBreakpoint - 1 }px ) {
    display: flex;
    flex-direction: column;
    aign-items: center;

    > *:first-child {
      margin-bottom: 0.6rem;
    }

    button,
    a {
      border-radius: 5px;
      box-shadow: none;
      font-size: ${ p => p.theme.typo.sizes.normal };
    }

    > *:not( :first-child ) {
      background: transparent;
    }
  }

  @media ( min-width: ${ navBreakpoint }px ) {
    display: flex;
    position: absolute;
    bottom: calc( -2rem - 4px );
    right: 0;
    overflow: hidden;
    padding: 0 3px 3px 3px;

    button,
    a {
      &:first-child {
        border-bottom-left-radius: 5px;
      }
      
      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
`

const StyledNavPanel = styled.div<{ $isOpen: boolean }>`
  position: relative;
  
  @media ( max-width: ${ navBreakpoint - 1 }px ) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8rem 1rem 2rem;
    background: ${ p => p.theme.colors.skyBlue };
    transform: translateX( ${ p => p.$isOpen ? '0' : '100%' } );
    transition: transform .3s ease-out;
  }
`

export default Nav
