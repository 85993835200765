import styled, { css } from 'styled-components'

function getPadding( $small = false ) {
  if ( $small ) {
    return {
      x: '0.8rem',
      y: '0.4rem',
    }
  }

  return {
    x: '1.6rem',
    y: '0.8rem',
  }
}

const Button = styled.div<{
  $hoverOutline?: boolean,
  $small?: boolean,
  $secondary?: boolean,
  $delete?: boolean,
}>`
  button,
  a,
  span {
    position: relative;
    overflow: hidden;
    display: inline-block;
    min-width: 100px;
    padding: ${ p => `${ getPadding( p.$small ).y } ${ getPadding( p.$small ).x }` };
    border: 0;
    border-radius: 5px;
    text-align: center;
    ${ p => p.theme.typo.satoshiBold };
    font-size: ${ p => p.$small ? p.theme.typo.sizes.small : p.theme.typo.sizes.dropped };
    text-decoration: none;
    transition:
      background .3s ease-out,
      color .3s ease-out;

    ${ p => p.$hoverOutline && css`
      padding: calc( ${ getPadding( p.$small ).y } - 1px ) calc( ${ getPadding( p.$small ).x } - 1px );
      border: 1px solid ${ p.theme.colors.yellow };
    ` }
  }

  button,
  a {
    background: ${ p => p.theme.colors.yellow };
    color: ${ p => p.theme.colors.navy };
    cursor: pointer;

    &:hover {
      background: ${ p => p.theme.colors.navy };
      color: ${ p => p.theme.colors.yellow };
    }

    ${ p => p.$secondary && css`
      background: ${ p.theme.colors.disabledIconBgGrey };
    ` }
    
    ${ p => p.$delete && css`
      padding: calc( ${ getPadding( p.$small ).y } - 1px ) calc( ${ getPadding( p.$small ).x } - 1px );
      border: 1px solid ${ p.theme.colors.red };
      background: ${ p.theme.colors.red };
      color: ${ p.theme.colors.white };

      &:hover {
        background: transparent;
        color: ${ p.theme.colors.red };
      }
    ` }
  }

  button:disabled {
    background: ${ p => p.theme.colors.disabledIconBgGrey };
    color: ${ p => p.theme.colors.disabledIconFgGrey };
    cursor: default;
  }

  span {
    background: ${ p => p.theme.colors.disabledIconBgGrey };
    border-color: ${ p => p.theme.colors.disabledIconBgGrey };
    color: ${ p => p.theme.colors.navy };
    opacity: 0.5;
  }
`

export default Button
