import { css } from 'styled-components'
import colors from './colors'

export const typo = {
  satoshi: css`
    font-family: 'Satoshi', sans-serif;
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
  `,
  satoshiBold: css`
    font-family: 'Satoshi', sans-serif;
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  `,
  sigmarOne: css`
    font-family: 'Sigmar One', cursive;
    font-weight:  400;
    font-style:   normal;
    line-height: 0.857;
  `,
  diaryNotes: css`
    font-family: 'Diary Notes', cursive;
    font-weight:  400;
    font-style:   normal;
    line-height: 0.857;
  `,
  sizes: {
    small: '0.777rem', // 14px
    dropped: '0.888rem', // 16px
    normal: '1rem', // 18px
    bumped: '1.222rem', // 22px
    medium: '1.555rem', // 28px
    large: '1.777rem', // 32px
    veryLarge: '2rem', // 36px
    hero: '2.666rem', // 48px
    pageTitle: '3.111rem', // 56px
  },
  urls: [
    'https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap',
  ],
}

const fonts = css`
  html,
  body,
  input,
  textarea,
  select,
  button {
    ${ typo.satoshi }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${ colors.navy };
    font-size: 18px;
    line-height: 1.35;
  }

  strong {
    ${ typo.satoshiBold }
  }
`

export default fonts
