import {
  createContext, useContext, useState, useMemo, useRef, 
} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import useModalTraits from '../hooks/useModalTraits'
import Image from './Image'

const LightboxContext = createContext( undefined )
LightboxContext.displayName = 'LightboxContext'

export const useLightboxContext = () => useContext( LightboxContext )

export function LightboxProvider( { children } ) {
  const [isOpen, setIsOpen] = useState( false )
  const [images, setImages] = useState( [] )

  const openLightbox = newImages => {
    setImages( newImages )
    setIsOpen( true )
  }

  const closeLightbox = () => setIsOpen( false )

  const lightbox = useMemo( () => ( {
    isOpen,
    images,
    openLightbox,
    closeLightbox,
  } ), [isOpen, images] )

  return (
    <LightboxContext.Provider value={ lightbox }>
      { children }
      <Lightbox />
    </LightboxContext.Provider>
  )
}

function Lightbox() {
  const { images, isOpen, closeLightbox } = useLightboxContext()
  const containerRef = useRef()
  const [index, setIndex] = useState( 0 )
  const image = images[ index ]

  const prevSlide = () => {
    if ( index > 0 ) {
      setIndex( s => s - 1 )
    }
  }
  
  const nextSlide = () => {
    if ( index < images.length - 1 ) {
      setIndex( s => s + 1 )
    }
  }

  useModalTraits( {
    isOpen,
    close: closeLightbox,
    containerRef,
  } )

  return (
    <StyledLightbox 
      ref={ containerRef }
      $isOpen={ isOpen }
    >
      <StyledOverlay />
      <StyledSlide onClick={ closeLightbox }>
        { image && (
          <div>
            <LightboxImage
              src={ image.src }
              width={ image.width }
              height={ image.height }
            />
            <StyledLightboxCloseButton onClick={ closeLightbox }>
              <span>×</span>
            </StyledLightboxCloseButton>
          </div>
        ) }
      </StyledSlide>
      { images.length > 1 && (
        <StyledControls>
          <button
            type="button"
            disabled={ index === 0 }
            onClick={ prevSlide }
          >
            Previous
          </button>
          <button
            type="button"
            disabled={ index === images.length - 1 }
            onClick={ nextSlide }
          >
            Next
          </button>
        </StyledControls>
      ) }
    </StyledLightbox>
  )
}

function LightboxImage( { src, width, height } ) {
  if ( src.endsWith( '.pdf' ) ) {
    return (
      <>
        <embed 
          src={ src }
        />
        <div />
      </>
    )
  }

  return (
    <Image
      src={ src }
      alt=""
      width={ width }
      height={ height }
      layout="raw"
    />
  )
}

const breakpoint = 600

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${ p => rgba( p.theme.colors.navy, 0.8 ) };
  opacity: 0;
  transition: opacity .3s ease-out;
`

const StyledControls = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY( -50% );
  opacity: 0;
  transition: opacity .3s ease-out;
`

const StyledSlide = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity .3s ease-out;

  > div {
    z-index: 1;
    position: relative;

    img {
      max-width: 85vw;
      max-height: 85vh;
    }

    embed {
      width: 85vw;
      height: 85vh;
    }
  }

  @media ( max-width: ${ breakpoint }px ) {
    > div {
      img {
        max-width: 100vw;
        max-height: 100vh;
      }

      embed {
        width: 100vw;
        height: 100vh;
      }
    }
  }
`

const StyledLightbox = styled.div<{ $isOpen: boolean }>`
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${ p => p.$isOpen ? 'auto' : 'none' };

  ${ p => p.$isOpen && css`
    ${ StyledOverlay } {
      opacity: 1;
    }
    
    ${ StyledControls } {
      opacity: 1;
    }

    ${ StyledSlide } {
      opacity: 1;
    }
  ` }
`

const StyledLightboxCloseButton = styled.button`
  position: absolute;
  top: -24px;
  right: -24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: inherit;
  background: ${ p => p.theme.colors.yellow };
  border: 0;
  border-radius: 48px;
  cursor: pointer;
  transition: background .3s ease-out;

  &:hover {
    background: ${ p => p.theme.colors.turquoise };
  }

  &:focus {
    outline: none;
  }
  
  &:focus-visible {
    background: ${ p => p.theme.colors.turquoise };
  }

  &:focus:not( :focus-visible ) {
    box-shadow: none;
  }

  span {
    margin-top: -5px;
  }

  @media ( max-width: ${ breakpoint }px ) {
    right: 5px;
  }
`

export default Lightbox
