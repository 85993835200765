import { ApolloProvider as Ap } from '@apollo/client'
import { useAuth } from '../lib/auth'
import client from './client'

function ApolloProvider( { children } ) {
  const me = useAuth()

  return (
    <Ap client={ client( me?.user?.accessToken ) }>
      { children }
    </Ap>
  )
}

export default ApolloProvider
