import styled from 'styled-components'
import Button from './Button'

const StyledTextContent = styled.article<{ $centered?: boolean }>`
  text-align: ${ p => p.$centered ? 'center' : 'left' };
  font-size: ${ p => p.theme.typo.sizes.bumped };
  line-height: 1.35;
  color: ${ p => p.theme.colors.white };

  ol li {
    margin-bottom: 1rem;
  }

  h2 {
    margin: 2rem 0;
    ${ p => p.theme.typo.diaryNotes }
    font-size: ${ p => p.theme.typo.sizes.large };
    color: ${ p => p.theme.colors.yellow };
  }

  svg {
    margin-top: 1rem;
  }

  ${ Button }:last-child {
    margin-top: 3rem;
  }
/* 
  p a:only-child {
    ${ p => p.theme.typo.satoshiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: inherit;

  } */
`

StyledTextContent.defaultProps = {
  $centered: true,
}

export default StyledTextContent
