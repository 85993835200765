import styled from 'styled-components'
import Container from './layout/Container'
import Stack from './layout/Stack'

const CDN_URL = process.env.NEXT_PUBLIC_CLOUDFRONT_URL

const partners = [
  {
    title: 'Common Seas',
    src: 'https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/partner-logos/CommonSeas_Logo_black-v2.png',
    href: 'https://commonseas.com/',
    maxWidth: 170,
  },
  {
    title: 'Ocean Generation',
    src: 'https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/partner-logos/ocean-generation.png',
    href: 'https://oceangeneration.org/',
    maxWidth: 120,
  },
  {
    title: 'World Ocean Day',
    src: 'https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/partner-logos/World Ocean Day – Black logo.png',
    href: 'https://worldoceanday.org/',
    maxWidth: 140,
  },
  {
    title: 'Escola Azul',
    src: 'https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/partner-logos/escola-azul.png',
    href: 'https://escolaazul.pt/en/',
    maxWidth: 140,
  },
  {
    title: 'Protect Blue',
    src: `${ CDN_URL }/logos/WOD-partner-logo-Protect-Blue-logo_built_by.png`,
    href: 'https://protect.blue/',
    maxWidth: 120,
  },
  {
    title: 'United Nations Decade of Ocean Science for Sustainable Development',
    src: 'https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/partner-logos/ENGLISH_BLACK-01.png',
    href: 'https://en.unesco.org/ocean-decade',
    maxWidth: 140,
  },
]

function Footer() {
  return (
    <StyledFooter>
      <StyledFooterBg />
      <StyledFooterUpper>
        <div className="footer-upper-column">
          <p>Powered by</p>
          <a target="_blank" rel="noreferrer" href="https://oceanconservationtrust.org/">
            { /* eslint-disable-next-line @next/next/no-img-element */ }
            <img className="partner-logo--oct" src="https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/oct.png" alt="Ocean Conservation Trust" />
          </a>
        </div>

        <div className="footer-upper-column">
          <a target="_blank" rel="noreferrer" href="https://www.mcsuk.org/">
            { /* eslint-disable-next-line @next/next/no-img-element */ }
            <img className="partner-logo--mcs" src="https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/mcs.png" alt="Marine Conservation Society" />
          </a>

          <a target="_blank" rel="noreferrer" href="https://www.msc.org/">
            { /* eslint-disable-next-line @next/next/no-img-element */ }
            <img className="partner-logo--msc" src="https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/msc.png" alt="Marine Stewardship Council" />
          </a>
        </div>
      </StyledFooterUpper>
      <StyledFooterInner>
        <Container>
          <Stack
            $top={ 3 }
            $bottom={ 3 }
          >
            <StyledFooterLogos>
              { partners.map( partner => (
                <a 
                  key={ partner.src }
                  aria-label={ partner.title }
                  href={ partner.href }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={ { 
                    maxWidth: partner.maxWidth ? `${ partner.maxWidth }px` : '180px',
                  } }
                >
                  { /* eslint-disable-next-line @next/next/no-img-element */ }
                  <img 
                    src={ partner.src }
                    alt={ partner.title }
                  />
                </a>
              ) ) }
            
            </StyledFooterLogos>
          </Stack>
        </Container>
      </StyledFooterInner>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  position: relative;
`

const StyledFooterBg = styled.div`
  position: relative;
  pointer-events: none;
  border-top: 20px solid #21aaa5;
  padding-bottom: 10%;
  background-color: #21aaa5;
  background-image: url(https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/whale-and-white-wave-v3.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left bottom;

  img {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    transform: translateY( -10% );
  }
`

const StyledFooterUpper = styled.div`
  overflow: hidden;
  background-image: url(https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/checker-grid.png);
  background-position: center center;
  background-size: 25px;
  text-align: center;

  .footer-upper-column {
    display: inline-block;
    width: calc(50% - 40px);
    max-width: 300px;
    vertical-align: top;
    min-width: 200px;

    p {
      text-align: left;
      font-weight: bold;
    }
  }

  a {
    padding: 20px;
    display: inline-block;
  }

  img {
    display: block;
    max-width: 220px;
    width: 100%;
  }

  .partner-logo--msc {
    max-width: 130px;
  }

  .partner-logo--mcs {
    max-width: 160px;
  }
`

const StyledFooterInner = styled.div`
  background: url(https://worldoceanday.school/wp-content/themes/world-ocean-day-2022/images/2024/beige-texture-v2.jpg);
  // background: ${ p => p.theme.colors.yellow };
  background-size: 100% auto;
  border-bottom: 40px solid #0b3d5d
`

const StyledFooterLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    flex: 0 0 auto;
    display: block;
    max-width: 180px;
    max-height: 90px;
    padding: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

export default Footer
