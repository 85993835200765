import { useId } from 'react'
import styled, { css } from 'styled-components'

function FormInput( {
  type = 'text',
  label,
  note = null,
  placeholder = null,
  errorMessage = null,
  options = null,
  value,
  onChange = null,
  autoComplete = 'nope',
  disabled = false,
  readOnly = false,
  checked = null,
} ) {
  const id = useId()
  const fieldName = `input_${ id }`

  return (
    <StyledInput
      $type={ type }
      $hasErrorMessage={ !!errorMessage }
    >
      { type !== 'checkbox' && <label htmlFor={ fieldName }>{ label }</label> }
      <Field
        type={ type }
        name={ fieldName }
        placeholder={ placeholder }
        options={ options }
        value={ value }
        onChange={ onChange }
        autoComplete={ autoComplete }
        disabled={ disabled }
        readOnly={ readOnly }
        checked={ checked }
      />
      { type === 'checkbox' && <label htmlFor={ fieldName }>{ label }</label> }
      { note && <StyledFieldNote>{ note }</StyledFieldNote> }
      { errorMessage && <StyledErrorMessage>{ errorMessage }</StyledErrorMessage> }
    </StyledInput>
  )
}

function Field( {
  type,
  name,
  placeholder,
  options,
  value,
  onChange,
  autoComplete,
  disabled,
  readOnly,
  checked,
} ) {
  if ( type === 'textarea' ) {
    return (
      <textarea
        id={ name }
        name={ name }
        value={ value }
        placeholder={ placeholder }
        onChange={ onChange }
        autoComplete={ autoComplete }
        rows={ 5 }
        disabled={ disabled }
        readOnly={ readOnly }
      />
    )
  }

  if ( type === 'select' ) {
    return (
      <select
        id={ name }
        name={ name }
        value={ value }
        onChange={ onChange }
        autoComplete={ autoComplete }
        disabled={ disabled }
      >
        { options.map( option => (
          <option
            key={ option.id }
            value={ option.id }
          >
            { option.name }
          </option>
        ) ) }
      </select>
    )
  }

  return (
    <input
      type={ type }
      id={ name }
      name={ name }
      value={ value }
      placeholder={ placeholder }
      onChange={ onChange }
      autoComplete={ autoComplete }
      disabled={ disabled }
      readOnly={ readOnly }
      checked={ checked }
    />
  )
}

interface StyledInput {
  $type: string,
  $hasErrorMessage?: boolean,
}

export const StyledInput = styled.div<StyledInput>`
  text-align: left;
  
  label,
  input:not( [type=checkbox] ):not( [type=radio] ),
  textarea,
  select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    line-height: 1.3;
  }

  label {
    ${ p => p.theme.typo.satoshiBold }
    margin-bottom: 0.4rem;
  }

  input:not( [type=checkbox] ):not( [type=radio] ),
  textarea,
  select {
    padding: 0.6rem 1rem;
    border: 1px solid ${ p => p.theme.colors.navy };
    border-radius: 5px;
    background: ${ p => p.theme.colors.transparent };

    &:focus-visible {
      outline: none;
      box-shadow: inset 0 0 0 2px ${ p => p.theme.colors.turquoise };
    }
  }

  input[type=checkbox] {
    -webkit-appearance: none;
    position: relative;
    margin: 0;
    margin-right: 0.5rem;
    padding: 0.6rem;
    background-color: ${ p => p.theme.colors.white };
    border: 1px solid ${ p => p.theme.colors.navy };
    border-radius: 5px;

    &:checked {
      background-color: ${ p => p.theme.colors.navy };
      border-color: ${ p => p.theme.colors.white };
      
      &::after {
        content: '✓';
        position: absolute;
        top: -2px;
        left: 4px;
        color: ${ p => p.theme.colors.white };
      }
    }
  }

  ${ p => p.$hasErrorMessage && css`
    input:not( [type=checkbox] ):not( [type=radio] ),
    textarea,
    select {
      border: 1px solid ${ p.theme.colors.red };
    }
  ` }

  ${ p => p.$type === 'checkbox' && css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    input,
    label {
      display: inline;
      width: auto;
    }

    label {
      margin: 0;
    }
  ` }
`

const StyledFieldNote = styled.span`
  display: block;
  flex: 1 0 100%;
  margin-top: 0.25rem;
  font-size: 0.85rem;
  opacity: 0.8;
`

const StyledErrorMessage = styled.span`
  display: block;
  padding: 0.5rem;
  font-size: 0.85rem;
  background: ${ p => p.theme.colors.red };
  color: ${ p => p.theme.colors.paper };
`

export default FormInput
