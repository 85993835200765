import {
  createContext, 
  useContext, 
  useMemo,
} from 'react'

const AppContext = createContext( {} )

AppContext.displayName = 'AppContext'

export const useAppContext = () => useContext( AppContext )

export function AppProvider( { children } ) {
  const value = useMemo( () => ( {} ), [] )

  return (
    <AppContext.Provider 
      value={ value }
    >
      { children }
    </AppContext.Provider>
  )
}
