import { useEffect } from 'react'
import styled from 'styled-components'
import { grantAnalytics, denyAnalytics, pageView } from '../lib/ga'
import Container from './layout/Container'
import CookieCutter, {
  AcceptButton,
  useCookieCutter,
} from '../lib/cookie-cutter/CookieCutter'
import Button from './Button'
import { textLinkWithHover } from '../styles/textLink'

const consentContent = {
  title: 'Our use of cookies',
  body: 'We use cookies on this site to improve your user experience. By continuing to use our site, you are agreeing to use our cookies.',
}

export function CookieConsentProvider( { children } ) {
  return (
    <CookieCutter
      userOptions={ [
        {
          handle: 'essential',
          name: 'Essential',
          initial: true,
          acceptAll: true,
          rejectAll: true,
          canEdit: false,
        },
        {
          handle: 'analytics',
          name: 'Analytics',
          initial: false,
          acceptAll: true,
          rejectAll: false,
        },
      ] }
    >
      { children }
    </CookieCutter>
  )
}

export function CookieConsentBanner() {
  const { currentView, userPreferences } = useCookieCutter()

  useEffect( () => {
    if ( userPreferences?.find( p => p.handle === 'analytics' )?.setting ) {
      grantAnalytics()
      pageView()
    }
    else {
      denyAnalytics()
    }
  }, [userPreferences] )

  if ( currentView !== 'banner' ) {
    return null
  }

  return (
    <StyledCookieConsent>
      <Container>
        <StyledCookieConsentInner>
          <StyledCopy>
            <h3>{ consentContent.title }</h3>
            <p>{ consentContent.body }</p>
          </StyledCopy>
          <Button>
            <AcceptButton>
              Accept
            </AcceptButton>
          </Button>
        </StyledCookieConsentInner>
      </Container>
    </StyledCookieConsent>
  )
}

const StyledCookieConsent = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${ p => p.theme.colors.white };
`

const StyledCookieConsentInner = styled.div`
  padding: 2.5rem 0;
  color: ${ p => p.theme.colors.black };

  ${ Button } {
    margin-top: 1.5rem;
  }

  @media all and ( max-width: 700px ) {
    flex-wrap: wrap;

    &:first-child {
      flex-grow: 1;
    }

    ${ Button } {
      margin: 1.3rem 1rem 0 0;
    }
  }
`

const StyledCopy = styled.div`
  max-width: 52rem;

  h3 {
    ${ p => p.theme.typo.generalSansSemiBold }
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    ${ textLinkWithHover }
    color: inherit;
  }
`
